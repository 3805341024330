<template>
  <div>
    <banner
      title="Daftar Pelaku Perbukuan Tersertifikasi"
      description="Pusat Perbukuan bekerja sama dengan lembaga sertifikasi profesi melaksanakan sertifikasi profesi terhadap penulis dan penyunting baik yang berada di lingkungan Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi maupun penulis dan penyunting yang berasal dari instansi lain atau masyarakat secara umum yang juga berkompeten."
      :breadcrumbs="breadcrumbs"
      :icon="require('@/assets/home/decoration/pelaku-perbukuan.svg')"
    />
    <section id="content" class="mt-4">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="sidebar p-3">
              <div class="mb-3 h6">Filter berdasarkan :</div>
              <div>
                <div
                  class="custom-control custom-checkbox"
                  v-for="option in roleOptions"
                  :key="option.value"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :value="option.value"
                    :checked="option.value === filter.role"
                    @change="changeRole($event, option.value)"
                    :id="option.label"
                  />
                  <label class="custom-control-label " :for="option.label">
                    {{ option.label }}
                  </label>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-3">
                <button
                  ref="loader"
                  @click="resetCertifications"
                  class="btn btn-outline-primary w-100 mr-1 rounded-pill"
                  type="button"
                >
                  Reset
                </button>
                <button
                  ref="loader"
                  @click="getCertifications(true)"
                  class="btn btn-primary w-100 ml-1 rounded-pill"
                  type="submit"
                >
                  Terapkan
                </button>
              </div>
            </div>
          </div>

          <div class="col-md-9">
            <div class="input-group mb-3" style="width: 300px;">
              <div class="input-group-prepend">
                <span class="input-group-text bg-transparent pr-2">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </span>
              </div>
              <input
                @input="searchName"
                class="form-control pl-0 border-left-0"
                placeholder="Cari nama"
              />
            </div>

            <div
              v-if="isLoading"
              style="height: 400px"
              class="d-flex justify-content-center align-items-center"
            >
              <img src="@/assets/loading-2.gif" alt="" class="img-fluid" />
            </div>
            <div v-else class="table-responsive">
              <table
                class="table result-table table-responsive border"
                style="display: table"
                data-pagination="true"
              >
                <thead class="thead-light">
                  <tr>
                    <th scope="col">Nama</th>
                    <th scope="col">Organisasi</th>
                    <th scope="col">Asesor</th>
                    <th scope="col">Tanggal Sertifikasi</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(certification, index) of certifications"
                    :key="index"
                  >
                    <td>{{ certification.name }}</td>
                    <td>{{ certification.organization }}</td>
                    <td>{{ certification.assessor }}</td>
                    <td>{{ certification.created_at }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="99">
                      <div
                        class="d-flex justify-content-between align-items-center"
                        v-if="certifications.length"
                      >
                        <span>
                          Menampilkan {{ metadata.perPage }} dari
                          {{ metadata.total_rows }} hasil
                        </span>

                        <pagination
                          class="m-0"
                          :current-page="pageNumber"
                          :total-data="metadata.total_rows"
                          :total-page="totalPage"
                          :key="totalPage"
                          :max-page="3"
                          @change="onChangePage"
                        />
                      </div>
                      <div v-else class="text-center">
                        Data tidak ditemukan
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <!-- row -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
.sidebar {
  border: 1px solid #dbddf0;
  border-radius: 0.5rem;
}
td {
  padding: 0.75rem;
  vertical-align: middle !important;
}
</style>
<script>
import axios from "axios";
import { BASE_URL } from "../../../store";
import Banner from "../../../components/homepage/Banner.vue";
import Pagination from "../../../components/common/Pagination.vue";
export default {
  components: { Banner, Pagination },
  data() {
    return {
      breadcrumbs: [
        {
          label: "Beranda",
          url: "/",
        },
        {
          label: "Pelaku Perbukuan Tersertifikasi",
          url: "",
        },
      ],
      roleOptions: [
        {
          label: "Penulis",
          value: "penulis",
        },
        {
          label: "Penelaah",
          value: "penelaah",
        },
        {
          label: "Editor",
          value: "editor",
        },
        {
          label: "Ilustrator",
          value: "ilustrator",
        },
      ],
      certifications: [],
      filter: {
        name: "",
        role: "",
      },
      pageNumber: 1,
      metadata: {
        perPage: 15,
        rowno: 0,
        total_rows: 0,
      },
      timeoutId: null,
      isLoading: false,
    };
  },
  computed: {
    totalPage() {
      return Math.ceil(this.metadata.total_rows / this.metadata.perPage);
    },
  },
  methods: {
    getCertifications(resetPage) {
      this.isLoading = true;
      if (resetPage) {
        this.pageNumber = 1;
      }
      axios
        .get(`${BASE_URL}api/training/training_report/${this.pageNumber}`, {
          params: {
            role: this.filter.role,
            name: this.filter.name,
          },
        })
        .then((res) => {
          const { results, per_page, rowno, total_rows } = res.data;
          this.certifications = results;
          const metadata = {
            perPage: +per_page,
            rowno: +rowno,
            total_rows: +total_rows,
          };
          this.metadata = metadata;
        })
        .finally(() => (this.isLoading = false));
    },
    resetCertifications() {
      this.filter = {
        name: "",
        role: "",
      };
      this.pageNumber = 1;
      this.getCertifications();
    },
    searchName(e) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.filter.name = e.target.value;
        this.getCertifications(true);
      }, 500);
    },
    changeRole(e, val) {
      if (e.target.checked) {
        this.filter.role = val;
      } else {
        this.filter.role = "";
      }
    },
    onChangePage(page) {
      this.pageNumber = page;
      this.getCertifications();
    },
  },
  created() {
    this.getCertifications();
  },
};
</script>
